import * as React from "react";
import type {ColumnsType} from 'antd/es/table';
import {
    Badge,
    Button,
    Card,
    Col, DatePicker,
    Dropdown,
    Form,
    Input,
    InputNumber, MenuProps,
    Modal,
    notification,
    Row, Select,
    Space,
    Spin,
    Switch,
    Table, TabsProps,
    Tag, Typography
} from "antd";
import {Tabs} from 'antd';
import {useEffect, useState} from "react";
import axiosAdminInstance from "../../other/api";
import {useParams} from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment/moment";
import {DownOutlined} from "@ant-design/icons";

const {Title, Paragraph, Text, Link} = Typography;

const UserDetails = () => {
    let {id} = useParams();
    const [loading, setLoading] = useState(false);
    const [sendingReward, setSendingReward] = useState(false);
    const [userState, setUserState] = useState<any>(null);
    const [userLogs, setUserLogs] = useState<any[]>([]);
    const [userLogsFiltered, setUserLogsFiltered] = useState<any[]>([]);
    const [menuItems, setMenuItems] = useState<any[]>([]);
    const [logsFromDate, setLogsFromDate] = useState<any>(dayjs().add(-7, 'day').toDate().toISOString());
    const [logsToDate, setLogsToDate] = useState<any>(dayjs().toDate().toISOString());
    const [logsPage, setLogsPage] = useState<any>(0);
    const [logsPageSize, setLogsPageSize] = useState<any>(2000);
    const [sendRewardModel, setSendRewardModel] = useState<any>(null);
    const [refillBalanceModel, setRefillBalanceModel] = useState<any>(null);
    const [logsSearch, setLogsSearch] = useState('');
    const [api, contextHolder] = notification.useNotification();

    const currencies = [
        {
            value: 100519997,
            label: "KCAL"
        },
        {
            value: 100519998,
            label: "FITFI"
        }
    ]

    const columns: ColumnsType<any> = [
        {
            title: 'Timestamp',
            dataIndex: 'timestamp',
            key: 'timestamp',
            render: (value) => <span>{moment(value).format("DD-MM-YYYY HH:mm:ss")}</span>,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action'
        },

    ];

    const items: TabsProps['items'] = userState ? [
        {
            key: '1',
            label: 'Sneakers',
            children: <Col span={24}>
                <Card title="Sneakers" bordered={false}>
                    <div style={{display: "flex", overflowX: "scroll", maxWidth: '90vw'}}>
                        {userState.dynSneakers?.updated?.map((sneaker: any) => <Card
                            style={{minWidth: 200, minHeight: 200, margin: 10}}
                            title={`${sneaker.ItemDetails.Rarity.Name}(${sneaker.ItemDetails.Type.Name}) #${sneaker.networkTokenId}`}>
                            <p>#{sneaker.NetworkTokenId}</p>
                            {sneaker.ItemDetails.Assets.map((asset: string) => <div style={{
                                position: "absolute",
                                top: 40,
                                right: 0,
                                bottom: 0,
                                left: 0,
                                display: "flex",
                            }}>
                                <img style={{maxHeight: 100}} src={asset}/>
                            </div>)}
                            <p style={{marginTop: 50}}>Comfort: {sneaker.ItemDetails.Comfort}</p>
                            <p>Efficiency: {sneaker.ItemDetails.Efficiency}</p>
                            <p>Luck: {sneaker.ItemDetails.Luck}</p>
                            <p>Resilience: {sneaker.ItemDetails.Resilience}</p>
                            {userState.dynUsers && userState.dynUsers.updated[0].shelfSneakers && userState.dynUsers.updated[0].shelfSneakers.updated?.findIndex((x: any) => x === sneaker.id) &&
                                <Tag color="red">On shelve</Tag>}

                            {userState.dynUsers && userState.dynUsers.updated[0].sneakerSellings && userState.dynUsers.updated[0].sneakerSellings.updated?.findIndex((x: any) => x.dynSneakerId === sneaker.id) &&
                                <Tag color="green">On selling</Tag>}

                        </Card>)}
                    </div>


                </Card>
            </Col>,
        },
        {
            key: '2',
            label: 'Headphones',
            children: <Col span={24}>
                <Card title="Headphones" bordered={false}>
                    <div style={{display: "flex", overflowX: "scroll", maxWidth: '90vw'}}>
                        {userState.dynHeadPhones?.updated?.map((sneaker: any) => <Card
                            style={{minWidth: 200, minHeight: 200, margin: 10}}
                            title={`${sneaker.ItemDetails.Rarity.Name} #${sneaker.networkTokenId}`}>

                            {sneaker.ItemDetails.Assets.map((asset: string) => <div style={{
                                position: "absolute",
                                top: 50,
                                right: 0,
                                bottom: 0,
                                left: 0,
                                display: "flex",
                            }}>
                                <img style={{maxHeight: 100}} src={asset}/>
                            </div>)}
                            <p style={{marginTop: 60}}>Comfort: {sneaker.ItemDetails.Comfort}</p>
                            <p>Efficiency: {sneaker.ItemDetails.Efficiency}</p>
                            <p>Luck: {sneaker.ItemDetails.Luck}</p>
                            <p>Resilience: {sneaker.ItemDetails.Resilience}</p>
                            {userState.dynUsers && userState.dynUsers.updated[0].shelfHeadPhones && userState.dynUsers.updated[0].shelfHeadPhones.updated?.findIndex((x: any) => x === sneaker.id) &&
                                <Tag color="red">On shelve</Tag>}

                            {userState.dynUsers && userState.dynUsers.updated[0].headPhoneSellings && userState.dynUsers.updated[0].headPhoneSellings.updated?.findIndex((x: any) => x.dynHeadPhoneId === sneaker.id) &&
                                <Tag color="green">On selling</Tag>}

                        </Card>)}
                    </div>


                </Card>
            </Col>,
        },
        {
            key: '3',
            label: 'Watches',
            children: <Col span={24}>
                <Card title="Wathes" bordered={false}>
                    <div style={{display: "flex", overflowX: "scroll", maxWidth: '90vw'}}>
                        {userState.dynWatches?.updated?.map((sneaker: any) => <Card
                            style={{minWidth: 200, minHeight: 200, margin: 10}}
                            title={`${sneaker.ItemDetails.Rarity.Name} #${sneaker.NetworkTokenId}`}>

                            {sneaker.ItemDetails.Assets.map((asset: string) => <div style={{
                                position: "absolute",
                                top: 60,
                                right: 0,
                                bottom: 0,
                                left: 0,
                                display: "flex",
                            }}>
                                <img style={{maxHeight: 60}} src={asset}/>
                            </div>)}

                        </Card>)}
                    </div>


                </Card>
            </Col>,
        },
        {
            key: '4',
            label: 'Shoeboxes',
            children: <Col span={24}>
                <Card title="ShoeBoxes" bordered={false}>
                    <div style={{display: "flex", overflowX: "scroll", maxWidth: '90vw'}}>
                        {userState.dynItems?.updated?.filter((item: any) => item.Title == 'Shoebox').map((item: any) =>
                            <Card
                                style={{minWidth: 200, minHeight: 200, margin: 10}}
                                title={`${item.shoeBox?.Rarity?.Name} #${item.shoeBox?.networkTokenId}`}>
                                <img style={{maxHeight: 60}} src={item.shoeBox?.Asset}/>


                            </Card>)}
                    </div>


                </Card>
            </Col>
        },
        {
            key: '5',
            label: 'Lootboxes',
            children: <Col span={24}>
                <Card title="ShoeBoxes" bordered={false}>
                    <div style={{display: "flex", overflowX: "scroll", maxWidth: '90vw'}}>
                        {userState.dynItems?.updated?.filter((item: any) => item.Title == 'Lootbox').map((item: any) =>
                            <Card
                                style={{minWidth: 200, minHeight: 200, margin: 10}}
                                title={`#${item.lootBox?.networkTokenId}`}>
                                <p>#{item.lootBox?.networkTokenId}</p>
                                <img style={{maxHeight: 60}} src={item.lootBox.Asset}/>


                            </Card>)}
                    </div>


                </Card>
            </Col>
        },
        {
            key: '6',
            label: 'Cases',
            children: <Col span={24}>
                <Card title="Cases" bordered={false}>
                    <div style={{display: "flex", overflowX: "scroll", maxWidth: '90vw'}}>
                        {userState.dynItems?.updated?.filter((item: any) => item.Title == 'Case').map((item: any) =>
                            <Card
                                style={{minWidth: 200, minHeight: 200, margin: 10}}
                                title={`${item.headPhoneBox?.Rarity?.Name} #${item.headPhoneBox?.networkTokenId}`}>
                                <p>#{item.headPhoneBox?.networkTokenId}</p>
                                <img style={{maxHeight: 60}} src={item.headPhoneBox?.Asset}/>


                            </Card>)}
                    </div>


                </Card>
            </Col>
        },
        {
            key: '7',
            label: 'Gems',
            children: <Col span={24}>
                <Card title="Gems" bordered={false}>
                    <div style={{display: "flex", overflowX: "scroll", maxWidth: '90vw'}}>
                        {userState.dynItems?.updated?.filter((item: any) => item.Title == 'Gem').map((item: any) =>
                            <Card
                                style={{minWidth: 200, minHeight: 200, margin: 10}}
                                title={`${item.gem?.Name} #${item.gem?.networkTokenId}`}>
                                <img style={{maxHeight: 60}} src={item.gem?.Asset}/>
                            </Card>)}
                    </div>


                </Card>
            </Col>
        }
    ] : [];

    const handleMenuClick: MenuProps['onClick'] = async (e) => {
        switch (e.key) {
            case '1':
                setSendRewardModel({})
                break;
            case '2':
                await recalculateMetrics();
                break;
            case '3':
                await disableWithdrawals(userState.withdrawalDisabled);
                break;
            case '4':
                setRefillBalanceModel({dynUserId: id});
                break;
        }
    };


    const loadState = async () => {
        try {

            const res = await axiosAdminInstance.get(`/users/state/${id}`);
            setUserState(res.data);
            const menu = [
                {
                    label: 'Send reward',
                    key: '1'
                },
                {
                    label: 'Recalculate metrics',
                    key: '2',
                }
            ];

            if (res.data.withdrawalDisabled) {
                menu.push({
                    label: 'Enable withdrawals',
                    key: '3',
                });
            }

            if (!res.data.withdrawalDisabled) {
                menu.push({
                    label: 'Disable withdrawals',
                    key: '3',
                });
            }

            menu.push({
                label: 'Refill balance',
                key: '4',
            });

            setMenuItems(menu);

        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {

        }
    }

    const filterLogs = (key: string) => {
        const filteredData = userLogs.filter(entry =>
            (!key || entry.action.toLowerCase().includes(key.toLowerCase()))
        );
        setUserLogsFiltered(filteredData);
    }

    const recalculateMetrics = async () => {
        try {

            await axiosAdminInstance.post(`/users/recalculateMetrics/${id}`);
            api['success']({
                message: 'Recalculation started. It might take few minutes'
            });
        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {

        }
    }

    const disableWithdrawals = async (isEnabled: boolean) => {
        try {

            await axiosAdminInstance.post(`/users/disableWithdrawals/${id}`, {enabled: isEnabled});
            api['success']({
                message: 'Saved'
            });
            await loadState();
        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {

        }
    }

    const loadLogs = async () => {
        try {
            setLoading(true);
            const res = await axiosAdminInstance.post(`/users/logs`, {
                page: logsPage, pageSize: logsPageSize, dynUserId: id, dateFrom: logsFromDate, dateTo: logsToDate
            });
            setUserLogs(res.data);
            setUserLogsFiltered(res.data);
        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {
            setLoading(false);
        }
    }

    const sendReward = async () => {
        try {
            setSendingReward(true);
            const res = await axiosAdminInstance.post(`/users/sendReward`, {
                rewardListId: sendRewardModel.rewardListId, dynUserId: id
            });
            api['success']({
                message: 'Sent'
            });

            setSendRewardModel(null);
            loadState();
            loadLogs();
        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {
            setSendingReward(false);
        }
    }

    const refillBalance = async () => {
        try {
            setSendingReward(true);
            const res = await axiosAdminInstance.post(`/users/refillBalance`, refillBalanceModel);
            api['success']({
                message: 'Done'
            });
            setRefillBalanceModel(null);
            loadState();
        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {
            setSendingReward(false);
        }
    }

    const changeRewardValue = (key: string, value: any) => {
        const record = {...sendRewardModel};
        record[key] = value;
        setSendRewardModel(record);
    }

    useEffect(() => {
        loadState();
        loadLogs();
    }, []);


    const changeRefillValue = (key: string, value: any) => {
        const record = {...refillBalanceModel};
        record[key] = value;
        setRefillBalanceModel(record);
    }

    // @ts-ignore
    return (
        <>
            {contextHolder}
            <Space wrap>
                <Dropdown menu={{
                    items: menuItems,
                    onClick: handleMenuClick
                }}>
                    <Button>
                        <Space>
                            Actions
                            <DownOutlined/>
                        </Space>
                    </Button>
                </Dropdown>
            </Space>

            {userState && userState.dynUsers?.updated.length > 0 && <Row gutter={16}>
                <Col span={8}>
                    <Card title="User details" bordered={false}>
                        <p>Email: {userState.dynUsers.updated[0].email} {userState.withdrawalDisabled &&
                            <Tag color="volcano">Disabled withdrawals</Tag>}</p>
                        <p>Name: {userState.dynUsers.updated[0].name}</p>
                        <p>Ref code: {userState.dynUsers.updated[0].referralCode}</p>
                        <p>Registration date: {userState.dynUsers.updated[0].registrationTimestamp}</p>

                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="Run and training" bordered={false}>
                        <p>Active
                            training: {userState.dynUsers?.updated[0].FitnessTraining?.ActiveFitnessTrainingId ? 'true' : 'false'}</p>
                        <p>Active
                            run: {userState.dynUsers.updated[0].run?.startTimestamp ? 'true' : 'false'}</p>
                        <p>Run start : {userState.dynUsers.updated[0].run?.startTimestamp}</p>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="Wallet" bordered={false}>
                        <p>KCAL
                            balance: {userState.dynItems?.updated?.find((item: any) => item.staticItemId == 100519997)?.count / 1000}</p>
                        <p>Trial KCAL
                            balance: {userState.dynItems?.updated?.find((item: any) => item.staticItemId == 100519993)?.count / 1000}</p>
                        <p>FITFI
                            balance: {userState.dynItems?.updated?.find((item: any) => item.staticItemId == 100519998)?.count / 1000}</p>
                    </Card>
                </Col>

                <Tabs defaultActiveKey="1" items={items}/>


            </Row>}


            <Space style={{marginTop: 10}}>
                <span>Start date</span>
                <div>
                    <DatePicker defaultValue={dayjs(logsFromDate)} showTime
                                onChange={(value, dateString) => {
                                    // @ts-ignore
                                    setLogsFromDate(dayjs.utc(value).toDate().toISOString())
                                }}/>
                </div>
                <span>End date</span>
                <div>
                    <DatePicker defaultValue={dayjs(logsToDate)} showTime
                                onChange={(value, dateString) => {
                                    // @ts-ignore
                                    setLogsToDate(dayjs.utc(value).toDate().toISOString())
                                }}/>
                </div>
                <div>
                    <Input
                        placeholder="Search"
                        value={logsSearch}
                        onChange={e => {
                            const currValue = e.target.value;
                            setLogsSearch(currValue);
                            filterLogs(currValue);
                        }}
                    />
                </div>
                <Button onClick={loadLogs}>Apply</Button>
            </Space>

            <Table rowKey="id" caption={<h3>Logs</h3>}
                   size={'small'}
                   pagination={{pageSize: 50, pageSizeOptions: [50, 100, 150, 200, 500]}}
                   loading={loading} columns={columns} dataSource={userLogsFiltered}/>

            {sendRewardModel && <Modal
                title={`Send reward`}
                open={!!sendRewardModel}
                onOk={sendReward}
                onCancel={() => setSendRewardModel(null)}
                okButtonProps={{disabled: sendingReward}}
                cancelButtonProps={{disabled: sendingReward}}
            >
                <div>
                    <div>
                        <span>Reward id</span>
                        <InputNumber
                            style={{width: '100%'}}
                            value={sendRewardModel.rewardListId}
                            onChange={e => changeRewardValue('rewardListId', e)}
                        />
                    </div>

                    <Title level={4}>How to get reward id?</Title>

                    <Paragraph>
                        Write to our Game Designer
                    </Paragraph>

                    <Title level={4}>Commonly used</Title>
                    <p>Common shoebox = 43001</p>
                    <p>Uncommon shoebox = 43002</p>
                    <p>Rare shoebox = 43003</p>
                    <p>Epic shoebox = 43004</p>
                    <p>Legendary shoebox = 43004</p>

                    <p>Common case = 2000001</p>
                    <p>Uncommon case = 2000002</p>
                    <p>Rare case = 2000003</p>

                </div>
            </Modal>
            }

            {refillBalanceModel && <Modal
                title={`Refill balance`}
                open={!!refillBalanceModel}
                onOk={refillBalance}
                onCancel={() => setRefillBalanceModel(null)}
                okButtonProps={{disabled: sendingReward}}
                cancelButtonProps={{disabled: sendingReward}}
            >
                <div>
                    <Space>
                        <span>Value</span>
                        <InputNumber
                            style={{width: '100%'}}
                            value={refillBalanceModel.count}
                            onChange={e => changeRefillValue('count', e)}
                        />
                        <Select
                            placeholder="Select a currency"
                            optionFilterProp="children"
                            value={refillBalanceModel.staticItemId}
                            style={{minWidth: 150}}
                            onChange={(t) => {
                                changeRefillValue('staticItemId', t);
                            }}
                            options={currencies}
                        />
                    </Space>
                </div>
            </Modal>
            }
        </>
    );
};

export {UserDetails};
